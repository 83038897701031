import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Company,
    currentUser,
    CurrentUser,
    getContrastColorOfHexColor,
    LocalStorageService,
} from '@sansys/crosslib';
import { select, Store } from '@ngrx/store';

@Component({
    selector: 'sys-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    public currentUser$: Observable<CurrentUser | undefined> = new Observable<
        CurrentUser
    >();
    constructor(
        private store: Store<any>,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        this.currentUser$ = this.store.pipe(select(currentUser));

        const companyData:
            | Company
            | undefined = this.localStorageService.getItem('companyData');
        if (companyData) {
            let primaryColor = companyData.primaryColor;
            if (!primaryColor) {
                primaryColor = '#075380';
            }
            const textColor = getContrastColorOfHexColor(primaryColor);
            document.body.style.setProperty('--primary-color', primaryColor);
            document.body.style.setProperty(
                '--primary-color-text',
                `${textColor}`
            );
        }
    }
}
