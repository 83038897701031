import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    BugReportData,
    currentUser,
    CurrentUser,
    logout,
    refresh,
    TrelloService,
    API_BASE_FILE_SERVER,
    hideSpinner,
    showSpinner,
    LocalStorageService,
} from '@sansys/crosslib';
import { select, Store } from '@ngrx/store';
import { BugReportComponent } from '@sansys/webapputils';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { ofType, Actions } from '@ngrx/effects';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'sys-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
    private subscription = new Subscription();

    public currentUser?: CurrentUser;
    logoUrl = 'assets/images/sansys.png';
    customerManual = '';
    public showSpinner = false;

    constructor(
        private readonly store: Store<any>,
        private readonly dialog: MatDialog,
        private readonly action$: Actions,
        private readonly translocoService: TranslocoService,
        private readonly trelloService: TrelloService,
        private readonly localStorageService: LocalStorageService,
        @Inject(API_BASE_FILE_SERVER) public readonly fileServer: string
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.store
                .pipe(
                    select(currentUser),
                    tap((currentU) => {
                        this.currentUser = currentU;
                        setTimeout(() => {
                            if (currentU?.user?.company?.logoUrl) {
                                this.logoUrl =
                                    this.fileServer +
                                    currentU.user.company.logoUrl;
                            }
                            if (currentU?.user?.company?.customerManual) {
                                this.customerManual =
                                    this.fileServer +
                                    currentU.user.company.customerManual;
                            }
                        });
                    })
                )
                .subscribe()
        );

        this.subscription.add(
            this.action$.pipe(ofType(showSpinner)).subscribe(() => {
                setTimeout(() => (this.showSpinner = true));
            })
        );
        this.subscription.add(
            this.action$.pipe(ofType(hideSpinner)).subscribe(() => {
                setTimeout(() => (this.showSpinner = false));
            })
        );
    }

    onLogout(): void {
        this.store.dispatch(logout());
    }

    onRefresh(): void {
        this.store.dispatch(refresh());
    }

    showBugDialog(): void {
        const dialogRef = this.dialog.open(BugReportComponent, {
            width: '400px',
            data: {},
        });

        dialogRef.afterClosed().subscribe((result: BugReportData) => {
            if (result) {
                this.trelloService.sendErrorToTrello(result).subscribe();
            }
        });
    }

    switchLanguage(): void {
        const currentLanguage = this.translocoService.getActiveLang();
        if (currentLanguage === 'medical_de-CH') {
            this.translocoService.setActiveLang('medical_fr-PF');
            this.localStorageService.setItem('customer-language', 'fr');
        } else {
            this.translocoService.setActiveLang('medical_de-CH');
            this.localStorageService.setItem('customer-language', 'de');
        }
        window.location.reload();
    }

    openCustomerManual(): void {
        window.open(this.customerManual, '_blank');
    }
}
