import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { tap, switchMapTo, map } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import {
    AuthService,
    initialCheckAuth,
    logout,
    setAccessRights,
    setCurrentWorktime,
    softLogin,
    TariffEntityService,
    userLogin,
} from '@sansys/crosslib';

@Injectable()
export class AuthEffects implements OnInitEffects {
    constructor(
        private injector: Injector,
        private authService: AuthService,
        private action$: Actions,
        private store: Store<any>
    ) {}

    login$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(userLogin),
                tap((action) => {
                    if (!action.currentUser.user.company) {
                        return;
                    }

                    if (
                        action.currentUser.type === 'transportLocation' ||
                        action.currentUser.type === 'station'
                    ) {
                        this.manuallyInjectEntityService().fetchAndStoreOwnTariff();
                    }

                    this.authService
                        .getCurrentWorkTime()
                        .subscribe((currentWorktime) =>
                            this.store.dispatch(
                                setCurrentWorktime({ currentWorktime })
                            )
                        );

                    this.authService
                        .getPermissions()
                        .subscribe((accessRights) => {
                            this.store.dispatch(
                                setAccessRights({ accessRights })
                            );
                        });
                })
            ),
        { dispatch: false }
    );

    softLogin$ = createEffect(() =>
        this.action$.pipe(
            ofType(softLogin),
            switchMapTo(this.authService.softLogin()),
            map((currentUser) => {
                if (!currentUser) {
                    return logout();
                }
                return userLogin({ currentUser });
            })
        )
    );

    logout$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(logout),
                tap(() => {
                    this.authService.logout();
                })
            ),
        { dispatch: false }
    );

    initialAuthCheck$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(initialCheckAuth),
                switchMapTo(this.authService.checkAuthentication())
            ),
        { dispatch: false }
    );

    private manuallyInjectEntityService(): TariffEntityService {
        return this.injector.get(TariffEntityService);
    }

    ngrxOnInitEffects(): Action {
        return initialCheckAuth();
    }
}
