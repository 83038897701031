import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import {
    API_BASE_URL_TOKEN,
    FEATURE_INFOS,
    TranslocoRootModule,
    reducers,
    metaReducers,
    PluralHttpUrlGenerator,
    JwtInterceptor,
    AuthModule,
    CompanyModule,
    API_BASE_FILE_SERVER,
    PLATFORM,
    HttpRequestInterceptor,
    MERCURE_URL,
    LocalStorageService,
} from '@sansys/crosslib';
import { environment } from '../environments/environment';
import { ErrorEffects, formlyTypes, formlyWrappers } from '@sansys/webapputils';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import {
    DefaultDataServiceConfig,
    EntityDataModule,
    EntityDefinitionService,
    HttpUrlGenerator,
} from '@ngrx/data';
import { FormlyModule } from '@ngx-formly/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { featureInfos } from './layout/feature-infos';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { featuresEntityMetadata } from './features/features-entity-metadata.map';
import { AuthEffects } from './common/auth.effects';
import localeCh from '@angular/common/locales/de-CH';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import localeFr from '@angular/common/locales/fr-PF';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: environment.server + 'api/',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LayoutModule,
        AppRoutingModule,
        AuthModule,
        CompanyModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionSerializability: false,
                strictStateSerializability: true,
            },
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        EffectsModule.forRoot([ErrorEffects, AuthEffects]),
        EntityDataModule.forRoot({}),

        FormlyModule.forRoot({
            wrappers: formlyWrappers,
            types: formlyTypes,
            validationMessages: [
                { name: 'required', message: 'Pflichtfeld, bitte ausfüllen.' },
            ],
            extras: { checkExpressionOn: 'modelChange' },
        }),
        FormlyMaterialModule,
        FormlyMatDatepickerModule,
        FormlyMatToggleModule,
        TranslocoRootModule,
        NgxPermissionsModule.forRoot(),
        NgHttpLoaderModule.forRoot(),
    ],
    providers: [
        {
            provide: LOCALE_ID,
            deps: [LocalStorageService],
            useFactory: (localStorageService: LocalStorageService) => {
                const language = localStorageService.getItem(
                    'customer-language'
                );
                if (language === 'fr') {
                    return 'fr-PF';
                }
                return 'de-CH';
            },
        },
        {
            provide: DefaultDataServiceConfig,
            useValue: defaultDataServiceConfig,
        },
        { provide: HttpUrlGenerator, useClass: PluralHttpUrlGenerator },
        {
            provide: API_BASE_URL_TOKEN,
            useValue: environment.server,
        },
        {
            provide: PLATFORM,
            useValue: 'customer',
        },
        {
            provide: API_BASE_FILE_SERVER,
            useValue: environment.fileServer,
        },
        {
            provide: MERCURE_URL,
            useValue: environment.mercureUrl,
        },
        {
            provide: FEATURE_INFOS,
            useValue: featureInfos,
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private eds: EntityDefinitionService) {
        registerLocaleData(localeCh, 'ch');
        registerLocaleData(localeCh, 'de-CH');
        registerLocaleData(localeFr, 'fr');
        registerLocaleData(localeFr, 'fr-PF');
        eds.registerMetadataMap(featuresEntityMetadata);
    }
}
