<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-button class="logo toolbar-button" routerLink="/">
                    <img class="logo-icon" [src]="logoUrl" alt="sansys logo" />
                </button>
                <div
                    class="toolbar-separator"
                    *ngIf="
                        currentUser?.transportLocation?.canSeeOffers ||
                        currentUser?.station?.canSeeOffers
                    "
                ></div>
                <button
                    routerLink="/features/offers/new"
                    mat-button
                    class="toolbar-button"
                    *ngIf="
                        currentUser?.transportLocation?.canSeeOffers ||
                        currentUser?.station?.canSeeOffers
                    "
                >
                    {{ 'Offerte' | transloco }}<br />{{
                        'Erstellen' | transloco
                    }}
                </button>
                <div
                    class="toolbar-separator"
                    *ngIf="
                        currentUser?.transportLocation?.canSeeOffers ||
                        currentUser?.station?.canSeeOffers
                    "
                ></div>
                <button
                    routerLink="/features/offers"
                    mat-button
                    class="toolbar-button"
                    *ngIf="
                        currentUser?.transportLocation?.canSeeOffers ||
                        currentUser?.station?.canSeeOffers
                    "
                >
                    {{ 'Offerten' | transloco }}
                </button>
                <div class="toolbar-separator"></div>
                <button
                    routerLink="/features/transports/new"
                    mat-button
                    class="toolbar-button"
                >
                    {{ 'Transport' | transloco }}<br />{{
                        'Bestellen' | transloco
                    }}
                </button>
                <div class="toolbar-separator"></div>
                <button
                    routerLink="/features/transports"
                    [queryParams]="{ type: 'open' }"
                    mat-button
                    class="toolbar-button"
                >
                    {{ 'Transporte' | transloco }}<br />{{
                        'Offen' | transloco
                    }}
                </button>
                <div class="toolbar-separator"></div>
                <button
                    routerLink="/features/transports"
                    [queryParams]="{ type: 'closed' }"
                    mat-button
                    class="toolbar-button"
                >
                    {{ 'Transporte' | transloco }}<br />{{
                        'Abgeschlossen' | transloco
                    }}
                </button>
                <div
                    class="toolbar-separator"
                    *ngIf="currentUser?.type === 'transportLocation'"
                ></div>
                <button
                    routerLink="/features/invoices"
                    mat-button
                    class="toolbar-button"
                    *ngIf="currentUser?.transportLocation?.canSeeInvoices"
                >
                    {{ 'Rechnungen' | transloco }}
                </button>
                <div
                    class="toolbar-separator"
                    *ngIf="currentUser?.type === 'transportLocation'"
                ></div>
                <button
                    routerLink="/features/statistic"
                    mat-button
                    class="toolbar-button"
                    *ngIf="currentUser?.type === 'transportLocation'"
                >
                    {{ 'Statistik' | transloco }}
                </button>
                <div class="toolbar-separator"></div>
            </div>
        </div>

        <div
            class=""
            fxFlex="0 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <div class="toolbar-separator"></div>

            <button mat-button class="toolbar-button" (click)="onRefresh()">
                <mat-icon
                    fontSet="fa"
                    fontIcon="fa-sync"
                    *ngIf="!showSpinner"
                ></mat-icon>
                <mat-icon
                    fontSet="fa"
                    fontIcon="fa-spinner-third"
                    class="sys-spin"
                    *ngIf="showSpinner"
                ></mat-icon>
            </button>

            <div class="toolbar-separator"></div>

            <button
                mat-button
                class="toolbar-button"
                (click)="switchLanguage()"
            >
                <mat-icon fontSet="fa" fontIcon="fa-language"></mat-icon>
            </button>

            <div class="toolbar-separator"></div>

            <button
                *ngIf="customerManual"
                mat-button
                class="toolbar-button"
                (click)="openCustomerManual()"
            >
                <mat-icon fontSet="fa" fontIcon="fa-question"></mat-icon>
            </button>
            <div *ngIf="customerManual" class="toolbar-separator"></div>

            <button
                mat-button
                [matMenuTriggerFor]="userMenu"
                class="toolbar-button"
            >
                {{ currentUser?.transportLocation?.companyName
                }}{{ currentUser?.station?.stationName }}
                <mat-icon
                    fxHide.xs
                    class="s-16"
                    fontSet="fa"
                    fontIcon="fa-ellipsis-v"
                ></mat-icon>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button
                    mat-menu-item
                    (click)="showBugDialog()"
                    data-testid="bugReportButton"
                >
                    <mat-icon fontSet="fa" fontIcon="fa-bug"></mat-icon>
                    <span>{{ 'Fehler melden' | transloco }}</span>
                </button>

                <button mat-menu-item class="" (click)="onLogout()">
                    <mat-icon fontSet="fa" fontIcon="fa-sign-out"></mat-icon>
                    <span>{{ 'Abmelden' | transloco }}</span>
                </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
