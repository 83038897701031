import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import {
    BugReportModule,
    ComponentsModule,
    FooterModule,
} from '@sansys/webapputils';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [LayoutComponent, ToolbarComponent],
    imports: [
        RouterModule,
        ComponentsModule,
        FlexModule,
        MatButtonModule,
        ExtendedModule,
        MatIconModule,
        MatToolbarModule,
        MatMenuModule,
        CommonModule,
        MatDialogModule,
        FooterModule,
        BugReportModule,
        TranslocoModule,
    ],
    exports: [LayoutComponent, ToolbarComponent],
})
export class LayoutModule {}
