export const featureInfos = {
    scheduling: {
        title: 'Transportverwaltung',
    },

    personell: {
        title: 'Personal',
    },

    qm: {
        title: 'Qualitätsmanagement',
    },

    invoicing: {
        title: 'Rechnungswesen',
    },

    warehouse: {
        title: 'Lagermanagement',
    },

    schedulingOverview: {
        title: 'Übersicht',
        fa_icon: 'fa-stream',
        type: 'item',
        url: '/modules/scheduling/scheduler',
    },
    schedulingOrders: {
        title: 'Bestellungen',
        fa_icon: 'clipboard-list-check',
        type: 'item',
        url: '/modules/scheduling/orders',
    },
    schedulingOffer: {
        title: 'Offerten',
        fa_icon: 'fa-envelope-open-text',
        type: 'item',
        url: '/features/offers',
        permission: 'offers|READ',
    },
    schedulingTransports: {
        title: 'Transporte',
        fa_icon: 'fa-car-building',
        type: 'item',
        url: '/modules/scheduling/transports',
    },
    schedulingVehicles: {
        title: 'Fahrzeuge',
        fa_icon: 'fa-cars',
        type: 'item',
        url: '/modules/scheduling/vehicles',
    },
    schedulingPassengers: {
        title: 'passengers',
        fa_icon: 'fa-users-medical',
        type: 'item',
        url: '/modules/scheduling/passengers',
    },
    schedulingCustomers: {
        title: 'Kundenadministrationen',
        fa_icon: 'fa-address-card',
        type: 'item',
        url: '/modules/scheduling/customer-administrations',
    },
    schedulingTransportLocations: {
        title: 'Start-/Zielorte',
        fa_icon: 'fa-map-marker-alt',
        type: 'item',
        url: '/modules/scheduling/transport-locations',
    },
    schedulingFacilities: {
        title: 'Standorte',
        fa_icon: 'fa-building',
        type: 'item',
        url: '/modules/basic/facilities',
    },
    schedulingInsurances: {
        title: 'Versicherungen',
        fa_icon: 'fa-gavel',
        type: 'item',
        url: '/modules/scheduling/insurances',
    },
    personellEmployees: {
        title: 'Mitarbeiter',
        fa_icon: 'fa-user-circle',
        type: 'item',
        url: '/modules/basic/employees',
    },
    worktimes: {
        title: 'Zeiterfassung',
        fa_icon: 'fa-user-clock',
        type: 'item',
        url: 'modules/worktimes',
    },
    personellSalary: {
        title: 'Lohnbuchhaltung',
        fa_icon: 'fa-abacus',
        type: 'item',
        url: 'modules/personell/salary',
    },
    qmChecklists: {
        title: 'Checklisten Vorlagen',
        fa_icon: 'fa-clipboard-list-check',
        type: 'item',
        url: '/modules/quality-management/checklists',
    },
    qmComplaints: {
        title: 'Beschwerden',
        fa_icon: 'fa-exclamation-circle',
        type: 'item',
        url: '/modules/quality-management/complaints',
    },
    qmStatistics: {
        title: 'QM-Statistiken',
        fa_icon: 'fa-chart-pie',
        type: 'item',
        url: '/modules/quality-management/statistics',
    },
    invoicingInvoices: {
        title: 'Rechnungen',
        fa_icon: 'fa-wallet',
        type: 'item',
        url: '/modules/scheduling/invoices',
    },
    invoicingDebtcollection: {
        title: 'Betreibungsbegehren',
        fa_icon: 'fa-alarm-clock',
        type: 'item',
        url: '/modules/invoicing/debtcollection',
    },
    invoicingLosses: {
        title: 'Verluste',
        fa_icon: 'fa-flag',
        type: 'item',
        url: '/modules/invoices/losses',
    },
    invoicingStatistics: {
        title: 'Finanzstatistik',
        fa_icon: 'fa-chart-line',
        type: 'item',
        url: '/modules/invoicing/statistics',
    },
    invoicingTariffs: {
        title: 'Tarife',
        fa_icon: 'fa-list',
        type: 'item',
        url: '/modules/scheduling/tariffs',
    },
    warehouseMaterials: {
        title: 'Materialien',
        fa_icon: 'fa-dolly',
        type: 'item',
        url: '/modules/warehouse/materials',
    },
    warehouseDevices: {
        title: 'Geräte',
        fa_icon: 'fa-phone-laptop',
        type: 'item',
        url: '/modules/warehouse/devices',
    },
    warehouseStatistics: {
        title: 'Lagerstatistik',
        fa_icon: 'fa-analytics',
        type: 'item',
        url: '/modules/warehouse/statistics',
    },
};
