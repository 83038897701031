import { EntityMetadataMap } from '@ngrx/data';

export const featuresEntityMetadata: EntityMetadataMap = {
    Facility: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    TransportLocation: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Insurance: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Transport: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    CustomerAdministration: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Tariff: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    TariffVersion: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Invoice: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Passenger: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Vehicle: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Employee: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Station: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    ProtocolTemplate: {
        // TODO: Wieder raushemen
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
};
