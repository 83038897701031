import { Component, OnInit } from '@angular/core';
import { loadCldr, setCulture } from '@syncfusion/ej2-base';
import {
    CompanyService,
    CurrentUser,
    LocalStorageService,
    userLogin,
} from '@sansys/crosslib';
import { Store } from '@ngrx/store';
import { FuseSplashScreenService } from '@sansys/webapputils';
import { TranslocoService } from '@ngneat/transloco';

declare var require: any;

loadCldr(
    require('cldr-data/main/de-CH/numbers.json'),
    require('cldr-data/main/fr-PF/numbers.json'),
    require('cldr-data/main/de-CH/ca-gregorian.json'),
    require('cldr-data/main/fr-PF/ca-gregorian.json'),
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/de-CH/timeZoneNames.json'),
    require('cldr-data/main/fr-PF/timeZoneNames.json'),
    require('cldr-data/supplemental/weekData.json') // To load the culture based first day of week
);

@Component({
    selector: 'sys-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private localStorage: LocalStorageService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private administrationService: CompanyService,
        private translocoServce: TranslocoService,
        private localStorageService: LocalStorageService,
        private store: Store<any>
    ) {}

    ngOnInit(): void {
        const domain = window.location.hostname;
        let subdomain: string;
        if (domain.indexOf('.') < 0 || domain.split('.')[0] === 'www') {
            subdomain = '';
        } else {
            subdomain = domain.split('.')[0];
        }

        if (subdomain) {
            this.administrationService
                .getCustomerRouteInfos(subdomain)
                .subscribe((infos) => {
                    if (infos.companyData) {
                        this.localStorageService.setItem(
                            'companyData',
                            infos.companyData
                        );
                    }
                });
        }

        const fetchedtUser = this.localStorage.getItem<CurrentUser>(
            'sansys_user'
        );
        if (fetchedtUser) {
            this.store.dispatch(userLogin({ currentUser: fetchedtUser }));
        }

        const language = this.localStorageService.getItem('customer-language');
        if (language === 'fr') {
            this.translocoServce.setActiveLang('medical_fr-PF');
            setCulture('fr-PF');
        } else {
            this.translocoServce.setActiveLang('medical_de-CH');
            setCulture('de-CH');
        }
    }
}
