import { Routes, RouterModule } from '@angular/router';

import { NgModule } from '@angular/core';

export const routes: Routes = [
    {
        path: 'features',
        loadChildren: () =>
            import('./features/features.module').then((m) => m.FeaturesModule),
    },
    {
        path: '',
        loadChildren: () =>
            import('./public-pages/pages.module').then((m) => m.PagesModule),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
